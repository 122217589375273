import React from "react";
import "./Products.scss";
import {default as style} from "./Products.scss.json";
import {Section} from "../components/Section";

export default function Products({}) {
  return <Section className={style.Products}>
    <h2>Products</h2>
    <p>A selection of our products, available at <a href={"/vendors"}>these vendors</a></p>
    <div className={style.productList} id={"products"}>
      <div>
        <div className={style.image} style={{
          backgroundImage: "url('/static/VinegarApple-4574-150x150.jpg')"
        }}>
        </div>
        <div className="title"><h4>Apple Cider Vinegar</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/BlissBalls-4603-1-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Bliss Balls</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/KrautDill-4643-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Dill Sauerkraut</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/LOVE-PRO-SMALL-8-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Ginger Beer</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/bread5-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Gluten Free Sourdough Bread</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/KombuchaGT-4429-1-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Green Tea Kombucha w/ Pomegranate</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/LOVE-PRO-SMALL-13-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Homemade Peanut Butter</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/JunTea-4563-1-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Jun Tea</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/Kefir-4437-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Kefir Yogurt</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/Kimchi-4611-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Kimchi</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/Tibicos-Lemongrass-4549-1-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Lemongrass &amp; Ginger Tibicos</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/IMG_3043-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Love Probiotics Mens Shirt</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/IMG_3055-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Love Probiotics Womens Shirts</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/KrautMustard-4629-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Mustard Sauerkraut</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/KombuchaWT-4448-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Organic White Tea Kombucha w/ Blueberries</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/Tibicos-Rosa-4465-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Rosa de Jamaica Tibicos</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/1-1147097845500-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Sourdough Bread</h4></div>

      </div>
      <div>
        <div className={style.image}
             style={{backgroundImage: "url('/static/VinegarStrawberry-4527-150x150.jpg')"}}>
        </div>
        <div className="title"><h4>Strawberry Hibiscus Vinegar</h4></div>

      </div>
    </div>

  </Section>;
}