import "./Home.scss"
import * as style from "./Home.scss.json"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeart} from "@fortawesome/free-regular-svg-icons/faHeart";
import {faBacteria} from "@fortawesome/free-solid-svg-icons/faBacteria";
import {faEarthAsia} from "@fortawesome/free-solid-svg-icons/faEarthAsia";
import {faRotate} from "@fortawesome/free-solid-svg-icons/faRotate";
import React from "react";
import {Section} from "../components/Section";
import Products from "./Products";

export default function Home() {
    return (
        <div className={style.Home}>
            <iframe width="100%" height="720" src="https://www.youtube.com/embed/Q61MuUgMEyQ?rel=0&amp;showinfo=0"
                    frameBorder="0" allowFullScreen={true}></iframe>
            <div className={style.subText}>
                <h2>Local - Raw - Live</h2>
                <p>We are a small business based in San Marcos la Laguna, Guatemala on Lake Atitlán. We produce healthy,
                    live, locally sourced, fermented probiotic foods and beverages, and we also offer a variety of
                    hands-on
                    fermentation workshops.</p>
                {/*<hr/>*/}
            </div>

            <Section className={style.features}>
                <div>
                    <div className={style.icon}>
                        <FontAwesomeIcon icon={faBacteria}/>
                    </div>
                    <h3>Live Cultures</h3>
                    <p>Rich in digestive enzymes and beneficial gut bacteria, live foods improve our digestion, boost
                        our
                        immune systems, and contribute to an overall sense of well-being.</p>

                </div>
                <div>
                    <div className={style.icon}>
                        <FontAwesomeIcon icon={faEarthAsia}/>
                    </div>
                    <h3>Local Products</h3>
                    <p>99% of our products are sourced from Guatemalan markets and small-scale producers.</p>

                </div>
                <div>
                    <div className={style.icon}>
                        <FontAwesomeIcon icon={faHeart}/>
                    </div>
                    <h3>Homemade</h3>
                    <p>All our products are made, bottled and delivered directly from our home to a store or restaurant
                        near
                        you.</p>

                </div>
                <div>
                    <div className={style.icon}>
                        <FontAwesomeIcon icon={faRotate}/>
                    </div>
                    <h3>Sharing Knowledge</h3>
                    <p>We regularly post interesting articles on our facebook page and offer a variety of fermentation
                        workshops from our home (Over 150 to date!)</p>

                </div>

            </Section>
            <Products />
        </div>
    );
}
