import React from "react";
import "./Links.scss";
import {default as style} from "./Links.scss.json";

export default function Links({}) {
    return <div className={style.Links}>
        <div className="qua_blog_section">
            <img width="100%" height="360"
                 src="/static/links.jpg"
            />
            <h2 className="col-xs-12"><strong>Some Helpful Sites for Information and Cultures</strong></h2>

            <div className={style.lists}>
                <div>
                    <h3><strong>Websites</strong></h3>
                    <h4><a href="https://www.facebook.com/LoveProbiotics/">Love Probiotics Facebook Page</a></h4>

                    <h4><a href="http://www.gemcultures.com/">GEM Cultures</a></h4>

                    <h4><a href="http://www.culturesforhealth.com/">Cultures For Health</a></h4>

                    <h4><a href="http://www.wildfermentation.com/">Wild Fermentation</a></h4>
                    <p>    &nbsp;
                    </p>
                </div>
                <div>
                    <h3><strong>Suggested Readings</strong></h3>

                    <h4><a href="http://www.wildfermentation.com/the-art-of-fermentation/">Art of Fermentation</a>
                    </h4>

                    <h4><a href="http://www.wildfermentation.com/wild-fermentation/">Wild Fermentation</a></h4>

                    <h4><a href="http://www.amazon.com/The-Alaskan-Bootleggers-Bible-Moonshine/dp/0967452406">Alaskan
                        Bootleggers Bible</a></h4>

                    <h4><a
                        href="http://www.amazon.com/Nourishing-Traditions-Challenges-Politically-Dictocrats/dp/0967089735">Nourishing
                        Traditions</a></h4>

                    <h4><a href="http://www.amazon.com/Sacred-Herbal-Healing-Beers-Fermentation/dp/0937381667">Sacred
                        and Herbal
                        Healing Beers</a></h4>

                    <h4><a href="http://www.amazon.com/10-Human-Microbes-Health-Happiness/dp/0062345982">10%
                        Human</a></h4>
                    <p></p></div>
            </div>
        </div>
    </div>;
};
