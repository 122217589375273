import "./Home.scss"
import React from "react";
import "./Vendors.scss";
import * as style from "./Vendors.scss.json";
export default function Vendors() {

    return <div className={style.Vendors}>
        <div className={style.location}>
            <h3>Antigua</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="http://www.caobafarms.com/">Caoba Farms</a>
                    </div>
                    <div className="info"><p>A biointensive organic farm located 10 minutes from the city center.
                        Volunteer
                        opportunities, farm store, markets.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="http://www.fatcatguatemala.com/">Fat Cat Coffee Shop &amp; Roastery</a>
                    </div>
                    <div className="info"><p>Great coffee shop with incredible atmosphere and friendly staff in the
                        center
                        of Antigua</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.lasvibrasantigua.com/">Las Vibras de Casbah</a>
                    </div>
                    <div className="info"><p>Great night club next to the Santa Catalina Arch in Antigua! North American
                        feel, has an open air terrace restaurant.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        La Fábrica
                    </div>
                    <div className="info"><p>Well done gym with various classes and equipment. Friendly staff.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.cincoazul.com/">Cinco Azul</a>
                    </div>
                    <div className="info"><p>Online health food store that is socially conscious with an ever expanding
                        list
                        of great local products. Home delivery service in Antigua and Guatemala City!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.laescalonia.com.gt/">La Escalonia</a>
                    </div>
                    <div className="info"><p>Landmark plant nursery in the heart of Antigua. Local products, gardening
                        supplies, and restaurant.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>San Marcos</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Super Marquencita
                    </div>
                    <div className="info"><p>Large international food selection. Local San Marcos owners.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Los Arboles
                    </div>
                    <div className="info"><p>Good selection in town center. Competitive pricing.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        El Central
                    </div>
                    <div className="info"><p>Lots of locally produced products.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Super Tienda 2
                    </div>
                    <div className="info"><p>New Tienda close to Del Lago with a good selection of high quality
                        products. A
                        sister store to Los Arboles.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        San Jose 2
                    </div>
                    <div className="info"><p>Small corner store that has the only ATM 5B in San Marcos!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.facebook.com/Shangri-la-Atitlan-677862125579990/">Shangri-la
                            Atitlan</a>
                    </div>
                    <div className="info"><p>Local store that features products exclusively produced in San Marcos la
                        Laguna, Guatemala. Located at the top of Barrio 2 close to the Eagles nest. Best coffee on the
                        lake!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Vida
                    </div>
                    <div className="info"><p>Guatemalan owned fusion restaurant. Relaxed vibes on the lake.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.facebook.com/circlescafebakery/">Circles Cafe</a>
                    </div>
                    <div className="info"><p>Located in the center of San Marcos. Offers local homemade food, with a
                        cafe
                        atmosphere.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className={style.location}>
            <h3>Guatemala City</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.cincoazul.com/">Cinco Azul</a>
                    </div>
                    <div className="info"><p>Online health food store that is socially conscious with an ever expanding
                        list
                        of great local products. Home delivery service in Antigua and Guatemala City!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://encaja.club/">Encaja Club</a>
                    </div>
                    <div className="info"><p>An online supermarket that is affordable and fast. Free delivery and
                        shipped to
                        your house in 48 hours or less with membership. Delivery to Antigua &amp; Guatemala City!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.dolcegelatoguatemala.com/">Dolce Gelato</a>
                    </div>
                    <div className="info"><p>Homemade, Artisanal, Italian Ice Cream in Cuatro Grados Norte in zona 4.
                        Vegan
                        options available</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.moonrisegt.com/">Moonrise, Vegan Restaurant</a>
                    </div>
                    <div className="info"><p>Vegan restaurant that specializes in delicious comfort food. Located in 4°
                        grados Norte.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>Panajachel</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Chalo's Grocery
                    </div>
                    <div className="info"><p>A good selection of international and healthy foods at reasonable
                        prices.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Sandra's
                    </div>
                    <div className="info"><p>If you are looking for hard to find grocery items in Panajachel, Sandra’s
                        probably has it. One of the best selections in town.</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="https://www.dolcegelatoguatemala.com/">Dolce Gelato</a>
                    </div>
                    <div className="info"><p>Homemade, Artisanal, Italian Ice Cream on Santander. Vegan options
                        available</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>San Pedro</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="http://www.sapavisa.com/">Salud Para la Vida</a>
                    </div>
                    <div className="info"><p>A small neighborhood health store In San Pedro bringing a little comfort
                        and a
                        lot of high quality healthy products to travelers, tourists and Guatemalans.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>Tecpán</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="http://pasajinak.com/">Hacienda Real</a>
                    </div>
                    <div className="info"><p>Guatemalan cuisine with local meats and cheeses. Great for large
                        groups.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>Tzununá</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        <a target="_blank" href="http://www.loveprobiotics.org/">Love Probiotics</a>
                    </div>
                    <div className="info"><p>Our home and little probiotic factory. Stop in Monday through Saturday
                        between
                        10am and 3pm. We sell at wholesale prices!</p>
                    </div>
                </div>
            </div>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        El Trebol
                    </div>
                    <div className="info"><p>Local Tienda. Good selection for the area. Good prices. Great family.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.location}>
            <h3>Xela</h3>
            <div className={style.vendor}>
                <div className="vendor-wrapper">
                    <div className={style.title}>
                        Catas
                    </div>
                    <div className="info"><p>Wine, Coffee, Jam, Local Artisanal Products</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}