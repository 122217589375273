import {Link} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Spinner from './components/Spinner';
import {Route, Routes} from 'react-router-dom';
import {Storage} from 'lincd/lib/utils/Storage';
import {FrontendFileStore} from 'lincd-server/lib/shapes/FrontendFileStore';

//Note that by default LINCD apps are set up with support for SCSS (sass) and CSS Modules
//So any .scss file needs to be imported by itself
import './App.scss';
//and then the .scss.json file needs to be imported to access the class names (this file will be automatically generated)
import style from './App.scss.json';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook"

//In React 18 you can use 'lazy' to import pages only when you need them.
//This will cause webpack to create multiple bundles, and the right one is automatically loaded
const Home = lazy(() => import('./pages/Home' /* webpackPrefetch: true */));
const Vendors = lazy(() => import('./pages/Vendors' /* webpackPrefetch: true */));
const Products = lazy(() => import('./pages/Products' /* webpackPrefetch: true */));
const Links = lazy(() => import('./pages/Links' /* webpackPrefetch: true */));
const About = lazy(() => import('./pages/About' /* webpackPrefetch: true */));

//store all quads in a file on the backend named 'main'
export const store = new FrontendFileStore('main');
Storage.setDefaultStore(store);

declare var window;
export default function App({assets = typeof window !== 'undefined' ? window['assetManifest'] : {}}) {
    return (
        <Html assets={assets} title="Love Probiotics - fermented probiotic foods and beverages & fermentation courses">
            <Suspense fallback={<Spinner/>}>
                <ErrorBoundary FallbackComponent={Error}>
                    <Content/>
                </ErrorBoundary>
            </Suspense>
        </Html>
    );
}

function Content() {
    return (
        <Layout>
            <div className={style.content}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Suspense fallback={<Spinner/>}>
                                <Home/>
                            </Suspense>
                        }
                    />
                    <Route
                        path="/vendors"
                        element={
                            <Suspense fallback={<Spinner/>}>
                                <Vendors/>
                            </Suspense>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <Suspense fallback={<Spinner/>}>
                                <About/>
                            </Suspense>
                        }
                    />
                    <Route
                        path="/links"
                        element={
                            <Suspense fallback={<Spinner/>}>
                                <Links/>
                            </Suspense>
                        }
                    />
                    <Route
                        path="/products"
                        element={
                            <Suspense fallback={<Spinner/>}>
                                <Products/>
                            </Suspense>
                        }
                    />
                </Routes>
            </div>
        </Layout>
    );
}

function Error({error}) {
    return (
        <div className={style.error}>
            <h1>Application Error</h1>
            <pre>{error.stack}</pre>
        </div>
    );
}

function Layout({children}) {
    return (
        <>
            <main className={style.main}>
                <Header/>
                {children}
            </main>
            <Footer/>

        </>
    );
}

function Header() {
    return (
        <header className={style.header}>
            {/*<h1>Love</h1>*/}
            <div className={style.logo}/>
            <nav className={style.menu}>
                <Link to={"/"}>♥ Home</Link>
                <Link to={"/about"}>♥ About us</Link>
                <a href={"/products"}>♥ Products</a>
                {/*<Link to={"/workshops"}>♥ Workshops</Link>     */}
                <Link to={"/vendors"}>♥ Vendors</Link>
                <Link to={"/links"}>♥ Links</Link>
            </nav>
        </header>
    );
}

function Footer() {
    return (
        <footer className={style.footer}>
            &copy; Love Probiotics - Late Atitlán - Tzununa, Guatemala<br/>
            Contact us at <a href="mailto:loveprobiotics@gmail.com">loveprobiotics@gmail.com</a><br />
            Find us on <a href={"https://www.facebook.com/LoveProbiotics/"} target={"_blank"}><FontAwesomeIcon icon={faFacebook} />  facebook</a>
        </footer>

    );
}

function Html({assets, children, title}) {
    return (
        <html lang="en">
        <head>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <link rel="shortcut icon" href="/static/favicon-192.png"/>
            <link rel="stylesheet" href={assets['main.css']}/>
            {assets['tailwind-cdn'] && <script src={assets['tailwind-cdn']}></script>}
            <title>{title}</title>
        </head>
        <body>
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<b>Enable JavaScript to run this app.</b>`,
            }}
        />
        {children}
        <script
            dangerouslySetInnerHTML={{
                __html: `assetManifest = ${JSON.stringify(assets)};`,
            }}
        />
        </body>
        </html>
    );
}
