import React from "react";
import "./About.scss";
import {default as style} from "./About.scss.json";
import {Section} from "../components/Section";

export default function About({}) {
    return <div className={style.About}>
        <Section>
            <img width="100%"
                 src="/static/about-us.jpg"
                 className="img-responsive wp-post-image" alt="aboutus"/>
            <h2><strong>About Love Probiotics</strong></h2>
            <p>We are a small business in San Marcos la Laguna, Guatemala that produces healthy, live, fermented foods
                and
                beverages. Our (ever expanding) product line currently includes&nbsp;<span
                    className="text_exposed_show">various types of raw sauerkrauts, raw vinegars, organic black, green and white tea kombuchas, ginger beer, sparkling water kefirs, jun tea, organic kefir yogurt, lacto-fermented hot sauce, super-food bliss balls, sourdough bread and more!</span>
            </p>
            <p>We regularly offer 1 day, 7 hour, intensive fermentation courses and teach retreats throughout the high
                season (December through July). &nbsp;Check out our workshops tab for upcoming dates and to
                register. &nbsp;If
                you have a group of 5 or more people interested in a workshop let us know and we will work with you to
                find
                a
                date that works for everyone. &nbsp;Follow us as well on Facebook and Instagram to see what is happening
                in
                the wild world of fermentation.</p>
            <p>For ordering information please contact us at <a
                href={"mailto:loveprobiotics@gmail.com"}>loveprobiotics@gmail.com</a></p>
        </Section><Section>
        <h3><strong>About Michelle</strong></h3>
        <p>Michelle is a certified yoga instructor, YTT anatomy teacher and a self-taught fermentation enthusiast. A
            lover of all things food and health related, Michelle studied permaculture and herbology for several years
            in
            Australia before moving to Lake Atitlan to take on the role of farm director at the Mystical Yoga Farm.
            There,
            she routinely taught classes on&nbsp;gardening, nutrition, cooking, natural soap and salve making, and of
            course, fermentation. When she’s not experienting with new ferments and flavors in her kitchen, Michelle
            enjoys having friends around for potluck dinners (to sample her latest, craziest ferments) and spending time
            in the garden.</p>
    </Section>
    </div>;
};
